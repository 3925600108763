import React, { useState, useEffect } from 'react';
import axios from 'axios';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';


function App() {
  const [pregunta, setPregunta] = useState('');
  const [respuesta, setRespuesta] = useState('');
  const [preguntaVoz, setPreguntaVoz] = useState(false);
  const { transcript, listening, resetTranscript, browserSupportsSpeechRecognition } = useSpeechRecognition();
                                                                           

  const cambioPregunta = (e) => {
    console.log('pregunta');
    console.log(e.target.value);
    setPregunta(e.target.value);
  };

  useEffect(() => {
    //startListening();
    console.log('use effect');
    console.log(transcript);
    setPregunta(transcript);
    
  }, [transcript]);

  const consultarOpenai = async () => {
    let datos = {
      prompt: pregunta,
    }

    const consulta = {
      method: 'POST',
      url: 'https://billetera.gruponeosistemas.com/uat/pregutaSocio',
      //url: 'https://api.openai.com/v1/chat/completions',
      headers:{  
          'Accept': 'application/json',
          'Content-Type':'multipart/form-data',
                   
      },  
      data: datos,
    };
      /*const response = await axios.post('https://api.openai.com/v1/chat/completions', {
        prompt: pregunta,
        //max_tokens: 150,
        //model:'gpt-3.5-turbo-0125'
      }, {
        headers: {
          'Authorization': `Bearer sk-proj-GZKTTTUUqsZzV8ZXpFG2T3BlbkFJ3bLybtr9EDvAeRxcfPBs`,
          'Accept': 'application/json',
          'Content-Type':'multipart/form-data',
        }
      });*/
    axios.request(consulta).then(function (response) {
      console.log('respuesta');
      console.log(response.data);
      setRespuesta(response.data.choices[0].message.content.trim());
      speakAnswer(response.data.choices[0].message.content.trim());
    }).catch(function () {
      console.error("Error al consumir el ws:");
      setRespuesta("No se puede procesar la solicitud.");
    });

  }

  /*const preguntarPorVoz = () => {
    const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
    const recognition = new SpeechRecognition();

    recognition.onstart = () => {
      setPreguntaVoz(true);
      console.log('Voice recognition started. Try speaking into the microphone.');
    };

    recognition.onspeechend = () => {
      setPreguntaVoz(false);
      recognition.stop();
      console.log('Voice recognition stopped.');
    };

    recognition.onresult = (event) => {
      const transcript = event.results[0][0].transcript;
      setPregunta(transcript);
      console.log('Voice input:', transcript);
    };

    recognition.start();
  }*/

  //const startListening = () => SpeechRecognition.startListening({ continuous: true, language: 'es-ES' });

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true, language: 'es-ES' });
    if (!listening) {
      alert("Por favor, otorgue permisos de micrófono cuando se le solicite.");
    }
  };

  const speakAnswer = (text) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = 'es-ES'; // Ajusta el idioma según sea necesario
    synth.speak(utterance);
  };

  if (!browserSupportsSpeechRecognition) {
    return <span>El navegador no admite el reconocimiento de voz.</span>;
  }




  return (
    
    <div style={contenedorSt}>
      <h1>Consultas sobre Mutuales</h1>
      <div className="App">
        <img src="https://billetera.gruponeosistemas.com/logos/logo_neo.png" className="rounded mx-auto d-block" alt="..." width="300" height="100"></img>
      </div>

      <textarea
        value={pregunta}
        onChange={cambioPregunta}
        placeholder="Escribe tu pregunta sobre Mutual"
        style={textareaSt}
      />
      {/* <button onClick={preguntarPorVoz} style={buttonSt}>
        {preguntaVoz ? 'Escuchando...' : 'Hablar'}
      </button> */}
      <div>
        {/* <button onClick={SpeechRecognition.startListening} style={buttonSt}> */}
        <button onClick={startListening} style={buttonSt}>
          {listening ? 'Escuchar...' : 'Hablar'}
        </button>
        <button onClick={resetTranscript} style={buttonSt}>Borrar</button>
      </div>
      {listening && <div style={listeningSt}>Escuchando...</div>}
      <button onClick={consultarOpenai} style={buttonSt}>Preguntar</button>
      {respuesta && <div style={preguntaSt}><strong>Respuesta:</strong> {respuesta}</div>}
    </div>
  );
}

const contenedorSt = {
  maxWidth: '600px',
  margin: '0 auto',
  textAlign: 'center',
  padding: '20px'
};

const textareaSt = {
  width: '100%',
  height: '100px',
  padding: '10px',
  fontSize: '16px'
};

const buttonSt = {
  marginTop: '10px',
  padding: '10px 20px',
  fontSize: '16px'
};

const preguntaSt = {
  marginTop: '20px',
  padding: '10px',
  backgroundColor: '#f0f0f0',
  borderRadius: '5px'
};

const listeningSt = {
  marginTop: '10px',
  fontSize: '16px',
  color: 'red'
};


export default App;
